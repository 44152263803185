const AdminPackingListTable = ({
  packingDataList,
  editMode,
  editField,
  handleQtyChange,
  saveQty,
  handleQtyKeyPress,
  toggleEdit,
  openLogModal,
  deleteData,
  lastElementRef,
}) => {
  function formatDate(dateString) {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  return (
    <table className='table actable' style={{ marginTop: '130px' }}>
      <thead>
        <tr>
          <th>Item Code</th>
          <th>Desc</th>
          <th>Item Grp</th>
          <th className='text-end'>Price</th>
          <th className='text-center'>Take</th>
          <th className='text-center'>Return</th>
          <th className='text-center'>Sell</th>
          <th></th>
          <th>Last Updated</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {packingDataList.length > 0 ? (
          packingDataList.map((item, index) => (
            <tr
              key={item._id}
              ref={index === packingDataList.length - 1 ? lastElementRef : null}
            >
              <td>{item.itemCode}</td>
              <td>{item.description}</td>
              <td>{item.itemGrp}</td>
              <td className='text-end'>{item.price.toFixed(2)}</td>
              <td className='text-center'>
                {editMode === item._id && editField === 'takeQty' ? (
                  <input
                    type='number'
                    className='form-control'
                    style={{ width: '100px', margin: 'auto' }}
                    value={item.takeQty}
                    onChange={(e) =>
                      handleQtyChange(item._id, e.target.value, 'takeQty')
                    }
                    onBlur={() => saveQty(item._id, 'takeQty')}
                    onKeyDown={(e) => handleQtyKeyPress(e, item._id, 'takeQty')}
                    onFocus={(e) => e.target.select()}
                    min='0'
                    autoFocus
                  />
                ) : (
                  <span
                    onClick={() =>
                      toggleEdit(item._id, 'takeQty', item.takeQty)
                    }
                    className='px-3'
                    style={{ cursor: 'pointer' }}
                  >
                    {item.takeQty}
                  </span>
                )}
              </td>
              <td className='text-center'>
                {editMode === item._id && editField === 'returnQty' ? (
                  <input
                    type='number'
                    className='form-control'
                    style={{ width: '100px', margin: 'auto' }}
                    value={item.returnQty}
                    onChange={(e) =>
                      handleQtyChange(item._id, e.target.value, 'returnQty')
                    }
                    onBlur={() => saveQty(item._id, 'returnQty')}
                    onKeyDown={(e) =>
                      handleQtyKeyPress(e, item._id, 'returnQty')
                    }
                    onFocus={(e) => e.target.select()}
                    min='0'
                    autoFocus
                  />
                ) : (
                  <span
                    onClick={() =>
                      toggleEdit(item._id, 'returnQty', item.returnQty)
                    }
                    className='px-3'
                    style={{ cursor: 'pointer' }}
                  >
                    {item.returnQty}
                  </span>
                )}
              </td>
              <td className='text-center'>{item.saleQty}</td>
              <td className='text-center'>
                {item.saleQty !== null &&
                Number.isInteger(item.saleQty) &&
                item.takeQty - item.returnQty !== item.saleQty
                  ? '❌'
                  : ''}
              </td>
              <td>
                <span style={{ cursor: 'pointer', marginRight: '10px' }}>
                  <i
                    className='bi-info-circle-fill'
                    style={{ color: '#00a6ff' }}
                    onClick={() => openLogModal(item._id)}
                  ></i>
                </span>
                {item.lastUptByCode ? item.lastUptByCode : ''}{' '}
                {item.updatedAt ? formatDate(item.updatedAt) : ''}
              </td>
              <td className='text-end'>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => deleteData(item._id)}
                >
                  <i className='bi bi-trash3' style={{ color: '#dc3545' }}></i>
                </span>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan='10'>No items found</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default AdminPackingListTable
