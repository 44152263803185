import React from 'react'

const CreateNewEvent = ({
  handleSubmit,
  handleChange,
  closeModal,
  eventTitle,
}) => {
  return (
    <>
      <div className='text-center h5 shadow-sm pb-3 bg-white rounded'>
        Add Event
      </div>
      <form className='mt-3'>
        <div className='form-group'>
          <label className='col-sm-12 h6 pt-3'>Event</label>
          <div className='col-sm-12'>
            <input
              type='text'
              name='eventTitle'
              className='form-control mt-2'
              value={eventTitle}
              onFocus={(e) => e.target.select()}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className='text-end mt-4'>
          <button
            type='submit'
            className='btn btn-outline-success btn-sm'
            onClick={handleSubmit}
          >
            <i className='bi bi-check-lg'></i> Submit
          </button>
          <button
            type='button'
            className='btn btn-outline-danger btn-sm ms-3'
            onClick={closeModal}
          >
            <i className='bi bi-x-lg'></i> Cancel
          </button>
        </div>
      </form>
    </>
  )
}

export default CreateNewEvent
