import React, { useEffect, useRef, useState, useCallback } from 'react'
import Layout from '../../../components/Layout/Layout'
import AddNewTracking from './AddNewTracking'
import axios from 'axios'
import moment from 'moment'
import UpdateStatusModal from './UpdateStatusModal'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import TrackingLogModal from './TrackingLog'

const AdminTrackingList = () => {
  const [trackings, setTrackings] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [currentStatus, setCurrentStatus] = useState('')
  const [currentTrackingId, setCurrentTrackingId] = useState(null)
  const [currentTrackingNo, setCurrentTrackingNo] = useState('')
  const [showLogModal, setShowLogModal] = useState(false)
  const [trackingLog, setTrackingLog] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const intervalIdRef = useRef(null)
  const observer = useRef()

  useEffect(() => {
    fetchTrackings(page)
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current)
        intervalIdRef.current = null
      }
    }
  }, [page])

  useEffect(() => {
    if (isProcessing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isProcessing])

  const fetchTrackings = async (page, reset = false) => {
    try {
      setIsLoading(true)
      const response = await axios.get('/api/v1/admin/tracking/list', {
        params: { page, limit: 30 },
      })
      const { data, totalPages } = response.data

      if (reset) {
        setTrackings(data)
      } else {
        const newTrackings = data.filter(
          (tracking) =>
            !trackings.some((existing) => existing._id === tracking._id)
        )
        setTrackings((prev) => [...prev, ...newTrackings])
      }
      setHasMore(page < totalPages)
    } catch (error) {
      console.error('Error fetching trackings:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleStatusClick = (tracking) => {
    setCurrentStatus(tracking.status)
    setCurrentTrackingId(tracking._id)
    setShowStatusModal(true)
  }

  const updateStatus = async (newStatus) => {
    try {
      const response = await axios.patch(
        `/api/v1/admin/tracking/update-status/${currentTrackingId}`,
        { status: newStatus }
      )
      if (response.data.success) {
        const updatedTrackings = trackings.map((tracking) =>
          tracking._id === currentTrackingId
            ? { ...tracking, status: newStatus }
            : tracking
        )
        setTrackings(updatedTrackings)
      } else {
        throw new Error('Failed to update status')
      }
    } catch (error) {
      console.error('Error updating status:', error)
      alert('Failed to update status')
    }
  }

  const handleTrackPosShipment = async () => {
    setIsLoading(true)
    setIsProcessing(true)

    const pollingController = new AbortController()
    const { signal } = pollingController
    intervalIdRef.current = setInterval(async () => {
      try {
        const statusResponse = await axios.get(
          '/api/v1/admin/tracking/pos-status',
          { signal }
        )
        if (!statusResponse.data.inProgress) {
          setIsProcessing(false)
          setIsLoading(false)
          toast.success('Tracking Completed')
          clearInterval(intervalIdRef.current)
          intervalIdRef.current = null
          setPage(1)
          fetchTrackings(1, true)
        }
      } catch (pollError) {
        if (pollError.name === 'CanceledError') {
          console.log('Polling request was canceled.')
        } else {
          console.error('Error checking tracking status:', pollError)
        }
      }
    }, 15000)

    try {
      await axios.post('/api/v1/admin/tracking/pos-shipment')
    } catch (error) {
      console.error('Error starting tracking operation:', error)
      toast.warning('Tracking is in progress...')
    }
    return () => {
      clearInterval(intervalIdRef.current)
      intervalIdRef.current = null
      pollingController.abort()
    }
  }

  const handleInfoClick = async (trackingId, trackingNo) => {
    try {
      const response = await axios.get(
        `/api/v1/admin/tracking/log/${trackingId}`
      )
      setTrackingLog(response.data)
      setCurrentTrackingNo(trackingNo)
      setShowLogModal(true)
    } catch (error) {
      console.error('Error fetching tracking log:', error)
      alert('Failed to fetch tracking log')
    }
  }

  const handleDeleteTracking = async (trackingId) => {
    if (window.confirm('Confirm delete this tracking?')) {
      try {
        await axios.delete(`/api/v1/admin/tracking/delete/${trackingId}`)
        setTrackings((prev) =>
          prev.filter((tracking) => tracking._id !== trackingId)
        )
      } catch (error) {
        console.error('Error deleting tracking:', error)
        alert('Failed to delete tracking')
      }
    }
  }

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading || isProcessing) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !isProcessing) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, hasMore, isProcessing]
  )

  return (
    <Layout title={'Tracking List'}>
      <div className='pt-3 mx-3'>
        <div className='text-end mb-1'>
          <button
            type='button'
            style={{ marginRight: '30px' }}
            className='btn btn-outline-success btn-sm'
            onClick={handleTrackPosShipment}
            disabled={isLoading || isProcessing}
          >
            {isLoading ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : isProcessing ? (
              'Processing...'
            ) : (
              'Start Tracking'
            )}
          </button>
          <button
            type='button'
            className='btn btn-outline-info btn-sm'
            onClick={() => setShowModal(true)}
          >
            Add New Tracking
          </button>
        </div>
        <div
          className='fw-bold mb-2'
          style={{ fontStyle: 'italic', color: 'red' }}
        >
          Use this only to track Pos Malaysia deliveries
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Date</th>
              <th scope='col'>Tracking No.</th>
              <th scope='col'>Tracking Data</th>
              <th scope='col'>Status</th>
              <th scope='col'>Recipient</th>
              <th scope='col'>Type</th>
              <th scope='col'>Remark</th>
              <th scope='col'></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {trackings.map((tracking, index) => (
              <tr
                key={tracking._id}
                ref={index === trackings.length - 1 ? lastElementRef : null}
              >
                <td style={{ whiteSpace: 'nowrap' }}>
                  {moment(tracking.insertDate).format('YYYY-MM-DD')}
                </td>
                <td>{tracking.trackingNo}</td>
                <td>
                  <div style={{ display: 'flex' }}>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        handleInfoClick(tracking._id, tracking.trackingNo)
                      }
                    >
                      <i
                        className='bi-info-circle-fill'
                        style={{ color: '#00a6ff' }}
                      ></i>
                    </span>
                    <span style={{ marginLeft: '10px', textIndent: '-2px' }}>
                      {tracking.data}
                    </span>
                  </div>
                </td>
                <td
                  style={{
                    color:
                      tracking.status === 1
                        ? '#28a745'
                        : tracking.status === 2
                        ? '#007bff'
                        : tracking.status === 3
                        ? '#ffc107'
                        : '',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleStatusClick(tracking)}
                >
                  {tracking.status === 1
                    ? 'Delivered'
                    : tracking.status === 2
                    ? 'Shipped'
                    : tracking.status === 3
                    ? 'Pending Shipment'
                    : ''}
                </td>
                <td>{tracking.recipient}</td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {tracking.type === 1 ? 'Church/PSO' : 'Online Order'}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>{tracking.remark}</td>
                <td className='text-end'>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleDeleteTracking(tracking._id)}
                  >
                    <i
                      className='bi bi-trash3'
                      style={{ color: '#dc3545' }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading && (
          <Spinner animation='border' className='d-block mx-auto' />
        )}
      </div>
      <AddNewTracking
        show={showModal}
        handleClose={() => setShowModal(false)}
        fetchTrackings={() => fetchTrackings(1, true)}
      />
      <TrackingLogModal
        show={showLogModal}
        onHide={() => setShowLogModal(false)}
        logs={trackingLog}
        trackingNo={currentTrackingNo}
      />
      <UpdateStatusModal
        show={showStatusModal}
        handleClose={() => setShowStatusModal(false)}
        currentStatus={currentStatus}
        updateStatus={updateStatus}
      />
    </Layout>
  )
}

export default AdminTrackingList
